import { Ref, ref } from 'vue';
import { $loading } from '@/api/index';
import { setDateFormat } from '@/utils/dateFormatter';
import { toast } from '@/utils/toast';

export interface Crdm {
  sicdcode: string;
  sicdname: string;
  flag_name: string;
  sa_date: string; 
  sale_qunt: number; 
  salecode: string; 
  salename: string; 
  dplace_code: string;
  dplace_name: string; 
  tpct: string; 
  itnmcode: string; 
  itnm: string; 
  sa_clntcode: string;
  clntname: string; 
  delivery_code: string; 
  d_name: string; 
  sa_carno: string;
  cnt: string;
  sa_date_nm: string;
  errChk: string;
  txtMsg: string;
}

export function UseCrdm() {

  const crdms: Ref<Crdm[]> = ref([]);
  //팀용
  const year = ref(setDateFormat(new Date(), 'YYYY'));
  const month = ref(setDateFormat(new Date(), 'MM'));

  const isConnected = ref(true);

  async function fetchDatas(pFlag: string, pcFrdt: string, pcTodt: string, pcSale: string) {
    await $loading(`/crdm?p_flag=${pFlag}&pc_frdt=${pcFrdt}&pc_todt=${pcTodt}&pc_sale=${pcSale}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          crdms.value = [];
          toast('데이터가 존재하지 않습니다.'); 
        } else {
          if(res.data[0].errChk == -1){
            toast(res.data[0].txtMsg); 
          }else{
            crdms.value = null;
            crdms.value = res.data;
          }
        }
      }
    );
  }      

  return {
    isConnected,
    fetchDatas,
    year,
    month,
    crdms,
  };
}
