<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="medium" @click="clear">Clear</ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button color="medium" @click="cancel">Cancel</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-item>
      <ion-input class="inputNm" type="text" placeholder="착지를 입력하세요." @input="searchGroup($event)"></ion-input>
    </ion-item>
  </ion-header>
  <ion-content class="my-modal-class4">
    <ion-list>
      <ion-item class="group-list" v-for="detail in filteredDplaceList" :key="detail">
        <ion-label ref="input" class="dplaceName" @click="sendSaleNm(detail)"> {{ detail }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonList,
  modalController,
  IonHeader,
  IonButtons,
} from "@ionic/vue";
import { reactive, defineComponent, } from 'vue';

export default defineComponent({
  components: {
    IonContent,
    IonToolbar,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonList,
    IonHeader,
    IonButtons
  },
  props: {
    seachingFlag: {
      type: String
    },
    distinctDplaceCodes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filteredDplaceList() {
      return this.distinctDplaceCodes;
    }
  },
  methods: {
    cancel() {
      return modalController.dismiss(null, 'cancel');
    },
    clear() {
      return modalController.dismiss(null, 'clear');
    },
    sendSaleNm(salenm) {
      return modalController.dismiss(salenm, 'confirm');
    },
  },
  setup(props) {

    const dplacelist = reactive({
      dplaceName: {}
    })

    const detail = reactive({
      dplaceName: ''
    });

    async function searchGroup(event) {
      const filter = event.target.value;


      const dplaceNames = Array.from(document.querySelectorAll('.dplaceName'));
      const groupLists = Array.from(document.querySelectorAll('.group-list'));

      this.filteredDplaceList.forEach((item, index) => {
        const shouldShow = props.distinctDplaceCodes.includes(item) && item.includes(filter);
        dplaceNames[index].style.display = shouldShow ? "flex" : "none";
        groupLists[index].style.display = shouldShow ? "flex" : "none";
      });
    }

    return { detail, dplacelist, searchGroup }
  },

});
</script>

<style scoped>
.search-input {
  display: block;
  padding: 4px 8px;
  margin: 10px auto;
  width: 300px;
  font-size: 16px;
  outline: none;
}

.group-list {
  margin: auto;

  display: flex;
}

.group-list ion-label {
  display: flex;
  justify-content: left;
  align-items: left;
  height: 32px;
  list-style-type: none;
}
</style>
